<template>
  <div class="page-layout__content">

    <ul class="nav nav-pills nav-justified mb-4" id="pills-tab" role="tablist">
      <li class="nav-item bg-light">
        <a class="nav-link" :class="{'active':tabs.first}" data-toggle="pill" href="javascript:void(0)" @click="tabSelect('first')" role="tab" aria-controls="pills-home" aria-selected="true">
          <span class="badge btn-circle bg-danger mr-1">1</span> {{ getTranslation('companyCreateInfo') }}
        </a>
      </li>
      <li class="nav-item bg-light">
        <a class="nav-link" :class="{'active':tabs.second,'disabled':false}" @click="tabSelect('second')" data-toggle="pill" href="javascript:void(0)" role="tab" aria-controls="pills-home" aria-selected="true">
          <span class="badge btn-circle bg-danger mr-1">2</span>{{ getTranslation('companyDocuments') }}
        </a>
      </li>
      <li class="nav-item bg-light">
        <a class="nav-link" :class="{'active':tabs.third,'disabled':false}" @click="tabSelect('third')" data-toggle="pill" href="javascript:void(0)" role="tab" aria-controls="pills-home" aria-selected="true">
          <span class="badge btn-circle bg-danger mr-1">3</span>{{ getTranslation('companyCreateRequisites') }}
        </a>
      </li>
      <li class="nav-item bg-light">
        <a class="nav-link" :class="{'active':tabs.fourth,'disabled':false}" @click="tabSelect('fourth')" data-toggle="pill" href="javascript:void(0)" role="tab" aria-controls="pills-home" aria-selected="true">
          <span class="badge btn-circle bg-danger mr-1">4</span>{{ getTranslation('companyCreateServices') }}
        </a>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade" :class="{'show active': tabs.first}">
        <div v-if="alert.show" class="alert" :class="{'alert-success':alert.success}" role="alert">
          {{ getTranslation('Company info saved successfully') }} !
        </div>
        <div class="row m-auto justify-content-between">
          <section class="section col-md-7 col-sm-12">
            <div class="section__header">
              <h4 class="section__title">
                {{ getTranslation('generalInfo') }}
              </h4>
            </div>
            <div class="section__body">
              <div class="form-group">
                <label class="form__label--block form__label">{{ getTranslation('title') }} <span class="required">*</span>
                </label>
                <input type="text" class="field" v-model.trim="form.title" :class="{'is-invalid':$v.form.title.$error}"
                       :placeholder="getTranslation('title')">
                <div v-if="$v.form.title.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form-group">
                <label class="form__label--block form__label">{{ getTranslation('code') }}</label>
                <input type="text" class="field" v-model.trim="form.code" :placeholder="getTranslation('code')">
              </div>

              <CountrySelect :required="true" :edit-country-id="form.country_id" v-model="form.country_id"/>

              <CitySelect v-if="form.country_id" :edit-item-id="form.city_id" v-model="form.city_id" :country-id="form.country_id" :description="'companyCityDescription'"/>

              <AirportSelect v-if="form.city_id" :edit-item-id="form.airport_id" v-model="form.airport_id" :description="'companyAirportDescription'"/>

              <div class="form__group">
                <label class="form__label   form__label--block" for="shortArea">{{ getTranslation('description') }}</label>
                <textarea v-model.trim="form.description" class="field  field--full-width  field--short-area" name="" id="shortArea" cols="30" rows="10"/>
              </div>
            </div>
          </section>
          <section class="section col-md-4 col-sm-12">
            <div class="section__header">
              <h4 class="section__title">
                {{ getTranslation('contacts') }}
              </h4>
            </div>
            <div class="section__body">
              <div class="form-group">
                <label class="form__label--block form__label">
                  {{ getTranslation('email') }} <span class="required">*</span>
                </label>
                <input type="email" class="field" v-model.trim="contactForm.email" :class="{'is-invalid':$v.contactForm.email.$error}"
                       :placeholder="getTranslation('email')">
                <div v-if="$v.contactForm.email.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form-group">
                <label class="form__label--block form__label">
                  {{ getTranslation('mobile') }} <span class="required">*</span>
                </label>
                <div v-if="showPhonCodes" class="input-group">

                  <PhoneCode add-class="input-group-prepend" required :edit-item-id="contactForm.mobile_code_id" v-model="contactForm.mobile_code_id" :items="phoneCodes"/>

                  <input type="text" class="field form-control" v-model.trim="contactForm.mobile" :class="{'is-invalid':$v.contactForm.mobile.$error}"
                         :placeholder="getTranslation('mobile')">
                </div>

                <div v-if="$v.contactForm.mobile.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form-group">
                <label class="form__label--block form__label">
                  {{ getTranslation('fax') }} <span class="required">*</span>
                </label>
                <input type="text" class="field" v-model.trim="contactForm.fax" :class="{'is-invalid':$v.contactForm.fax.$error}"
                       :placeholder="getTranslation('fax')">
                <div v-if="$v.contactForm.fax.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form__group">
                <label class="form__label   form__label--block" for="address">{{ getTranslation('address') }}<span class="required">*</span></label>
                <textarea v-model.trim="contactForm.address" :class="{'is-invalid':$v.contactForm.address.$error}" class="field  field--full-width  field--short-area" id="address" cols="30" rows="10"/>
                <div v-if="$v.contactForm.address.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form__group">
                <label class="form__label   form__label--block" for="company_address">{{ getTranslation('legalAddress') }}<span class="required">*</span></label>
                <textarea v-model.trim="contactForm.company_address" :class="{'is-invalid':$v.contactForm.company_address.$error}" class="field  field--full-width  field--short-area" id="company_address" cols="30"
                          rows="10"/>
                <div v-if="$v.contactForm.company_address.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
            </div>
          </section>
        </div>
        <div class="row m-auto justify-content-end">
          <div class="mt-5 section__controls">
            <div class="section__controls-group">
              <button type="button" @click.prevent="saveCompany" class="btn btn-lg btn-primary">{{ getTranslation('next') }}</button>
            </div>
            <div class="section__controls-group">
              <router-link :to="{name:'company.getList'}" class="btn btn-lg btn-secondary ">{{ getTranslation('cancel') }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" :class="{'show active': tabs.second}">
        <div class="row m-auto justify-content-between">
          <section class="section col-md-6 col-sm-12">
            <div class="section__header">
              <h4 class="section__title">
                {{ getTranslation('documents') }}
              </h4>
            </div>
            <div class="section__body">
              <div class="form-group">
                <label class="form__label--block form__label">{{ getTranslation('document') }} <span class="required">*</span>
                </label>
                <VSelect
                    :options="formOptions.documentTypes"
                    :reduce="option=>option.id"
                    v-model="document.type_id"
                    label="title"
                    :class="{ 'is-invalid': $v.document.type_id.$error }"
                    :placeholder="getTranslation('selectFromList')"
                />
                <div v-if="$v.document.type_id.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form-group">
                <label class="form__label--block form__label">{{ getTranslation('file') }} <span class="required">*</span>
                </label>
                <input @change="uploadFile($event)" type="file" class="form-control-file"
                       :class="{'is-invalid':$v.document.file}">
                <div v-if="$v.document.file.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>
              <div class="form-group">
                <label class="form__label--block form__label">{{ getTranslation('description') }}
                </label>
                <textarea v-model.trim="document.description" class="field  field--full-width  field--short-area" cols="30" rows="10"/>
              </div>
              <div class="form-group">
                <div class="section__controls-group">
                  <button type="button" @click.prevent="sendDocument" class="btn btn-lg btn-primary">{{ getTranslation('saveDocument') }}</button>
                </div>
              </div>
            </div>
          </section>
          <section class="section col-md-5 col-sm-12">
            <div class="section__header">
              <h4 class="section__title">
                {{ getTranslation('documents') }}
              </h4>
            </div>
            <div class="section__body">
              <p v-if="documents.length < 1" class="section__descr">{{ getTranslation('notAddedYet') }}</p>
              <DocumentsComponent v-else :documents="documents"></DocumentsComponent>
            </div>
          </section>
        </div>
        <div class="row m-auto justify-content-end">
          <div class="mt-5 section__controls">
            <div class="section__controls-group">
              <button type="button" @click.prevent="()=>{activateStep('third');tabSelect('third')}" class="btn btn-lg btn-primary">{{ getTranslation('next') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" :class="{'show active': tabs.third}">
        <section class="section col-md-12 col-sm-12">
          <div class="section__header">
            <h4 class="section__title">
              {{ getTranslation('requisites') }}
            </h4>
          </div>
          <div class="section__body">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('bankName') }} <span class="required">*</span>
                  </label>
                  <input type="text" class="field" v-model.trim="requisite.bank_name" :class="{'is-invalid':$v.requisite.bank_name.$error}"
                         :placeholder="getTranslation('bankName')">
                  <div v-if="$v.requisite.bank_name.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                </div>
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('inn') }}<span class="required">*</span>
                  </label>
                  <input type="text" class="field" v-model.trim="requisite.inn"  :class="{'is-invalid':$v.requisite.inn.$error}" :placeholder="getTranslation('inn')">
                  <div v-if="$v.requisite.inn.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                </div>
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('bik') }}<span class="required">*</span>
                  </label>
                  <input type="text" class="field" v-model.trim="requisite.bik"  :class="{'is-invalid':$v.requisite.bik.$error}" :placeholder="getTranslation('bik')">
                  <div v-if="$v.requisite.bik.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                </div>
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('swift') }} <span class="required">*</span>
                  </label>
                  <input type="text" class="field" v-model.trim="requisite.swift" :class="{'is-invalid':$v.requisite.swift.$error}"
                         :placeholder="getTranslation('swift')">
                  <div v-if="$v.requisite.swift.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                </div>
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('multiCurrency') }}</label>
                  <input type="text" class="field" v-model.trim="requisite.multi" :placeholder="getTranslation('multiCurrency')">
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('USD') }} <span class="required">*</span>
                  </label>
                  <input type="text" class="field" v-model.trim="requisite.USD" :class="{'is-invalid':$v.requisite.USD.$error}"
                         :placeholder="getTranslation('USD')">
                  <div v-if="$v.requisite.USD.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                </div>
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('KGS') }}
                  </label>
                  <input type="text" class="field" v-model.trim="requisite.KGS" :placeholder="getTranslation('KGS')">
                </div>
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('RUB') }}
                  </label>
                  <input type="text" class="field" v-model.trim="requisite.RUB" :placeholder="getTranslation('RUB')">
                </div>
                <div class="form-group">
                  <label class="form__label--block form__label">{{ getTranslation('EUR') }}
                  </label>
                  <input type="text" class="field" v-model.trim="requisite.EUR" :placeholder="getTranslation('EUR')">
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="row m-auto justify-content-end">
          <div class="mt-5 section__controls">
            <div class="section__controls-group">
              <button type="button" @click.prevent="saveRequisites" class="btn btn-lg btn-primary">{{ getTranslation('next') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" :class="{'show active': tabs.fourth}">
        <div class="row m-auto justify-content-between">
          <section class="section col-md-12 col-sm-12">
            <div class="section__header">
              <h4 class="section__title">
                {{ getTranslation('services') }}
              </h4>
            </div>
            <div class="section__body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="form__label--block form__label">{{ getTranslation('serviceCategory') }} <span class="required">*</span>
                    </label>
                    <VSelect
                        :options="formOptions.services"
                        :reduce="option=>option.id"
                        v-model="servicesForm.services"
                        :multiple="true"
                        label="title"
                        :class="{ 'is-invalid': $v.servicesForm.services.$error }"
                        :placeholder="getTranslation('selectFromList')"
                    />
                    <div v-if="$v.servicesForm.services.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="form__label--block form__label">{{ getTranslation('serviceOptions') }} <span class="required">*</span>
                    </label>
                    <VSelect
                        :options="formOptions.serviceOptions"
                        :reduce="option=>option.id"
                        v-model="serviceOptionsForm.serviceOptions"
                        :multiple="true"
                        label="airTitle"
                        :class="{ 'is-invalid': $v.serviceOptionsForm.serviceOptions.$error }"
                        :placeholder="getTranslation('selectFromList')"
                    />
                    <div v-if="$v.serviceOptionsForm.serviceOptions.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="row m-auto justify-content-end">
          <div class="mt-5 section__controls">
            <div class="section__controls-group">
              <button type="button" @click.prevent="saveServices" class="btn btn-lg btn-primary">{{ getTranslation('save') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import required from "vuelidate/lib/validators/required";
import CountrySelect from "@/components/shared/CountrySelect";
import CitySelect from "@/components/shared/CitySelect";
import AirportSelect from "@/components/shared/AirportSelect";
import companyControllerMixin from "@/views/admin/companies/mixins/CompanyController";
import companyActionMixin from "@/views/admin/companies/mixins/companyAction";
import DocumentsComponent from "@/components/shared/DocumentsComponent";
import PhoneCode from "@/components/shared/PhoneCode";
import phoneMixin from "@/components/mixins/PhoneCode";

export default {
  name: "Create",
  components: { PhoneCode, DocumentsComponent, AirportSelect, CitySelect, CountrySelect },
  mixins: [ companyControllerMixin, companyActionMixin, phoneMixin ],
  data() {
    return {
      alert: {
        show: false,
        success: false
      },
      tabs: {
        first: true,
        second: false,
        third: false,
        fourth: false
      },
      tabsDone: {
        first: false,
        second: false,
        third: false,
        fourth: false
      },
      form: {
        id: null,
        title: '',
        code: '',
        description: '',
        system_default: false,
        country_id: '',
        city_id: '',
        airport_id: '',
      },
      contactForm: {
        id: null,
        email: '',
        phone: '',
        phone_code_id: 118,
        fax: '',
        mobile: '',
        mobile_code_id: 118,
        website: '',
        postal_code: '',
        address_url: '',
        address: '',
        company_address: ''
      },
      documents: [],
      document: {
        company_id: '',
        type_id: '',
        title: '',
        description: '',
        file: ''
      },
      formData: {},
      requisite: {
        inn: '',
        bik: '',
        swift: '',
        bank_name: '',
        multi: '',
        KGS: '',
        USD: '',
        RUB: '',
        EUR: '',
        company_id: '',
      },
      servicesForm: {
        services: [],
        company_id: '',
      },
      serviceOptionsForm: {
        serviceOptions: [],
        company_id: '',
      }
    }
  },
  created() {
    this.fetchFormOptions();
    this.setHeadings({
      title: this.getTranslation('addingCompany'),
      toBack: {
        label: this.getTranslation('toBack', this.getTranslation('companies')),
        route: { name: 'company.getList' }
      },
      breadcrumbs: [
        {
          text: this.getTranslation('companies'),
          to: { name: 'company.getList' }
        },
        {
          text: this.getTranslation('addingCompany')
        }
      ]
    });

  },
  methods: {
    saveCompany() {

      this.$v.form.$touch();
      this.$v.contactForm.$touch();

      if ( this.$v.form.$invalid )
        return this.snotifyError('wrongFormMsg');


      if ( this.$v.contactForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.form.contact = { ...this.contactForm };

      this.save(this.form).then(response => {

        this.setAlert();

        this.form.id = response.data.data.item.id;

        this.snotifySuccess('saveSuccessMsg');

        setTimeout(() => {
          this.activateStep('first');
          this.tabSelect('second');
        }, 2000);

      }).catch(() => {
      }).finally(() => {
        this.setAlert(false);
      });

    },
    uploadFile(e) {
      if ( e.target.files.length > 0 && e.target.files[0] ) {

        let file = e.target.files[0];

        let form = new FormData;

        form.append('file', file);

        this.document.company_id = this.form.id;
        this.document.file = file;
        this.$v.document.$touch();

        if ( this.$v.document.$invalid )
          return this.snotifyError('wrongFormMsg');

        for ( let prop in this.document ) {
          form.append(prop, this.document[prop]);
        }

        this.formData = form;

      }
    },
    saveRequisites() {

      this.requisite.company_id = this.form.id;

      if ( !this.form.id )
        return this.snotifyError('addGeneralInfo');

      this.$v.requisite.$touch();

      if ( this.$v.requisite.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.addRequisites(this.requisite)
          .then(response => {
            setTimeout(() => {
              this.activateStep('fourth');
              this.tabSelect('fourth');
            }, 2000);
          }).catch(() => {

      });

    },
    sendDocument() {

      if ( !this.form.id )
        return this.snotifyError('addGeneralInfo');

      this.addDocument(this.formData).then(response => {
        this.documents.push(response.data.data);
        this.$v.document.$reset();
        this.snotifySuccess('saveSuccessMsg');
        this.resetDocument();
      }).catch(() => {

      });
    },
    saveServices() {
      this.servicesForm.company_id = this.form.id;
      this.serviceOptionsForm.company_id = this.form.id;

      if ( !this.form.id )
        return this.snotifyError('addGeneralInfo');

      this.$v.servicesForm.$touch();
      this.$v.serviceOptionsForm.$touch();

      if ( this.$v.servicesForm.$invalid || this.$v.serviceOptionsForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.addServices(this.servicesForm)
          .then(() => {
            this.addServiceOptions(this.serviceOptionsForm)
                .then(() => {
                  this.$router.push({ name: 'company.get', params: { id: this.form.id } });
                }).catch(() => {
            });
          }).catch(() => {

      });
    },

    resetDocument() {
      this.document = {
        company_id: '',
        type_id: '',
        title: '',
        description: '',
        file: ''
      };
      this.formData = {};
    },

    setAlert(val = true) {
      this.alert.show = val;
      this.alert.success = val;
    },

    activateStep(step) {
      this.tabsDone[step] = true;
    },
  },
  validations: {
    servicesForm: {
      company_id: {
        required
      },
      services: {
        required
      }
    },
    serviceOptionsForm: {
      company_id: {
        required
      },
      serviceOptions: {
        required
      }
    },
    form: {
      title: {
        required
      },
      country_id: {
        required
      }
    },
    requisite: {
      inn: {
        required
      },
      bik: {
        required
      },
      swift: {
        required
      },
      bank_name: {
        required
      },
      USD: {
        required
      },
      company_id: {
        required
      }
    },
    contactForm: {
      email: {
        required
      },
      mobile: {
        required
      },
      mobile_code_id: {
        required
      },
      phone_code_id: {
        required
      },
      company_address: {
        required
      },
      address: {
        required
      },
      fax: {
        required
      }
    },
    document: {
      type_id: {
        required
      },
      company_id: {
        required
      },
      file: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>